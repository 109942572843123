<template>
	<div class="home">
		<nav>
			<div class="wrapper">
				<div class="wrap">
					<div class="logo">
						<img src="@/assets/images/logo_cn.png" v-if="language=='zh_CN'">
						<img src="@/assets/images/logo_en.png" v-else>
					</div>
					<ul class="nav">
						<li>{{$t('home')}}</li>
						<li>{{$t('about')}}</li>
						<li @click="scrollTo">{{$t('download')}}</li>
					</ul>
				</div>
				<div class="lang" @click="languageChange"><img src="@/assets/images/lang.png" width="20"><p>{{language=='zh_CN'?'中文':'EN'}}</p></div>
			</div>
		</nav>
		<div class="theme">
			<div class="wrapper">
				<div class="con">
					<img src="@/assets/images/title_okchat.png" v-if="language=='zh_CN'">
					<p v-html="$t('theme.desc')"></p>
					<div class="button-area" v-if="platform==='android'">
						<div class="android" @click="appDownload('android')"></div>
						<div class="googleplay" @click="appDownload('googleplay')"></div>
					</div>
					<div class="button-area" v-else-if="platform==='ios'">
						<div class="ios" @click="appDownload('ios')"></div>
					</div>
				</div>
				<div class="pic">
					<img src="@/assets/images/screenshot.png">
				</div>
			</div>
		</div>

		<section class="model1">
			<div class="wrapper">
				<div class="pic">
					<img src="@/assets/images/pic1.png">
				</div>
				
				<div class="con">
					<img src="@/assets/images/title_safe.png" class="til" v-if="language=='zh_CN'">
					
					<div class="til-m" v-if="language=='zh_CN'">
					</div>
					<div class="til-m title" v-else style="background:none">
						{{ $t('model1.title') }}
					</div>
					<p>{{$t('model1.desc')}}</p>
					<div class="button-area">
						<div class="android"></div>
						<div class="ios"></div>
					</div>
				</div>
				<div class="path"></div>
			</div>
			
		</section>
		<section class="model2">
			<div class="con">
				<img src="@/assets/images/title_talk.png" class="til" v-if="language=='zh_CN'">
				<div class="title" v-else>{{$t('model2.title')}}</div>
				<p>{{$t('model2.desc')}}</p>
			</div>
			<div class="wrapper">
				
				<ul>
					<li>
						<img src="@/assets/images/icon01.png">
						<h3>{{$t('model2.li01.title')}}</h3>
						<p>{{$t('model2.li01.desc')}}</p>
					</li>
					<li>
						<img src="@/assets/images/icon02.png">
						<h3>{{$t('model2.li02.title')}}</h3>
						<p>{{$t('model2.li02.desc')}}</p>
					</li>
					<li>
						<img src="@/assets/images/icon03.png">
						<h3>{{$t('model2.li03.title')}}</h3>
						<p>{{$t('model2.li03.desc')}}</p>
					</li>
					<li>
						<img src="@/assets/images/icon04.png">
						<h3>{{$t('model2.li04.title')}}</h3>
						<p>{{$t('model2.li04.desc')}}</p>
					</li>
					<li>
						<img src="@/assets/images/icon05.png">
						<h3>{{$t('model2.li05.title')}}</h3>
						<p>{{$t('model2.li05.desc')}}</p>
					</li>
					<li>
						<img src="@/assets/images/icon06.png">
						<h3>{{$t('model2.li06.title')}}</h3>
						<p>{{$t('model2.li06.desc')}}</p>
					</li>
					
				</ul>
			</div>
			
		</section>
		<!-- <section class="model3">
			<div class="wrapper">				
				<div class="con">
					<img src="@/assets/images/title_video.png" class="til" v-if="state.language=='zh_CN'">
					<p class="title" v-else v-html="$t('model3.title"></p>
					<p>{{$t('model3.desc}}</p>
					<div class="button-area">
						<div class="android"></div>
						<div class="ios"></div>
					</div>
				</div>
				<div class="pic">
					<img src="@/assets/images/pic2.png">
				</div>
				<div class="path"></div>
			</div>
			
		</section>
		<section class="model4">
			<div class="con">
				<img src="@/assets/images/title_group.png" class="til" v-if="state.language=='zh_CN'">
				<p class="title" v-else>{{$t('model4.title}}</p>
				<p>{{$t('model4.desc}}</p>
			</div>
			<img src="@/assets/images/pic_group.jpg">
		</section> -->
		<section class="model5" ref="download" id="download">
			<div class="wrapper">
				<div class="con">
					<img src="@/assets/images/title_download.png" class="til" v-if="language=='zh_CN'">
					<p class="title" v-else>{{$t('model5.title')}}</p>
					<p>{{$t('model5.desc')}}</p>

					<div class="button-area" v-if="platform==='android'">
						<div class="android" @click="appDownload('android')"></div>
						<div class="googleplay" @click="appDownload('googleplay')"></div>
					</div>
					<div class="button-area" v-else-if="platform==='ios'">
						<div class="ios" @click="appDownload('ios')"></div>
					</div>
					<div class="button-area" v-else>
						<div class="android" @click="appDownload('android')"></div>
						<div class="googleplay" @click="appDownload('googleplay')"></div>
						<div class="ios" @click="appDownload('ios')"></div>
					</div>
				</div>
				<div class="pic">
					<img src="@/assets/images/screenshot.png">
				</div>
			</div>
		</section>
		<footer>
			<div class="wrapper">
				<div class="wrap">
					<div class="logo">
						<img src="@/assets/images/logo_cn.png" v-if="language=='zh_CN'">
						<img src="@/assets/images/logo_en.png" v-else>
					</div>
					<p>{{$t('footer.desc')}}</p>
					<p>Copyright © 2023-2025 Suixinliao.com. All rights reserved.</p>

					<ul class="nav" v-if="language=='zh_CN'">
						<li><router-link :to="{name:'privacy_cn'}">{{$t('footer.privacy')}}</router-link></li> | 
						<li><router-link :to="{name:'agreement_cn'}">{{$t('footer.clientAgreement')}}</router-link></li>
					</ul>
					<ul class="nav" v-else>
						<li><router-link :to="{name:'privacy'}">{{$t('footer.privacy')}}</router-link></li> | 
						<li><router-link :to="{name:'agreement'}">{{$t('footer.clientAgreement')}}</router-link></li>
					</ul>
				</div>
				<div class="lang" @click="languageChange()"><img src="@/assets/images/lang.png" width="20"><p>{{language=='zh_CN'?'中文':'EN'}}</p></div>
			</div>
		</footer>
	</div>
</template>

<script>
	import '@/assets/css/base.css';
	import '@/utils/rem.js';
	import { useRoute } from 'vue-router';
	import { defineComponent, reactive, computed, ref, toRefs, onMounted } from "vue";
	import { useStore } from "vuex";
	import config from "@/utils/config";
	import $api from "@/utils/api";
	import { supportedLanguages } from '@/utils/config';
	import {sysPlatform} from "@/utils/util";
	import { useI18n } from 'vue-i18n';
	import {languageInit} from "@/utils/util";
import i18n from '@/plugins/i18n';

	export default {
	name: 'Home',
	setup(){
		const $i18n = useI18n();
		const {t} = useI18n();
		const $store = useStore();
		const $route = useRoute();
		const state = reactive({
			language:'zh_CN',
			langObj:{},
			platform:'',
			louis:null,
			versionInfo:{
				ios:"https://apps.apple.com/cn/app/suixinliao/id6468228611",
				android:"",
				googleplay:"https://play.google.com/store/apps/details?id=com.okchat.global"
			}
		});

		const download = ref(null);
		const scrollTo = ()=>{
			window.scrollTo({
				// y方向坐标800px（代码中不需要写明单位）
				top: download.value.getBoundingClientRect().top,
				// 滚动方式是平滑滚动 默认是auto 即instant 直接跳到目标位置  不常用
				behavior: "smooth",
			});
		}
		
		const languageInita = async ()=>{
            /* const sysLanguage = window.navigator.language;
			if($route.query.language!=undefined&&$route.query.language!='') state.language = $route.query.language;
			if(state.language!=undefined){
                const targetLang = supportedLanguages.filter((item)=>item.lang.indexOf(state.language)>-1);

                if(targetLang.length>0){
                    $i18n.locale.value = await languageInit(targetLang[0].lang);
					return;
                }
			} */
			
			let lang = ''
			if($route.query.language!=undefined&&$route.query.language!='') state.language = $route.query.language;
			if(state.language){
                const targetLang = supportedLanguages.filter((item)=>item.lang.indexOf(state.language)>-1);
                if(targetLang.length>0){
                    lang = targetLang[0].lang;
                }
			}
            
            $i18n.locale.value = await languageInit(lang);
            state.language = $i18n.locale.value;
		}
		const languageChange = ()=>{
			let targetLang = '';
			if(state.language=='zh_CN'){
				targetLang = 'en_US';
			}else{
				targetLang = 'zh_CN';
			}
			state.language = targetLang;
			$i18n.locale.value = state.language;
		}

		const getVersionInfo=()=>{
            $api.getJSON('site.webdown',{lang:state.language}).then((res)=>{
                if(res!=null){
					state.versionInfo.android = "https://file.669669.net/apk/"+res.data;
                }
            });
        }
		getVersionInfo()

		const appDownload=(platform)=>{
			
			if(platform=='android'){
				window.open(state.versionInfo.android)
			}else if(platform=='googleplay'){
				window.location.href=state.versionInfo.googleplay;
			}else{
				window.location.href=state.versionInfo.ios;
			}

		}
		/* const appDownload=(platform)=>{
			state.louis.install();
		} */

		onMounted(() => {
            window.document.title = t('appName');
			state.platform = sysPlatform();
			//openInstall
			/* const s = document.createElement("script");
			s.type = "text/javascript";
			s.src = "//sdk.mybitdigital.com/web/louis.js"; //在线引用

			//初始化
			s.addEventListener(
			"load",
			() => {
				var data = Louis.parseUrlParams(); //解析获取参数
				new Louis(
				{
					appKey: "av4n3ikb",//根据自己项目配置
					//openinstall初始化完成的回调函数，可选
					onready: function () {
						state.louis = this;
					}
				},
				data
				);
			},
			false
			);
			document.head.appendChild(s); */
        });
	

		languageInita();

		return {
			...toRefs(state),
			download,
			scrollTo,
			languageChange,
			appDownload
		}
	}

}
</script>
<style lang="scss">
	@import url('@/assets/css/home.css');
	
</style>