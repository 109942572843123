import { createStore } from "vuex";

export default createStore({
	state: {
		userInfo:'fsasafsafsa'
	},
	getters: {},
	mutations: {
		SETUSERINFO(state, val) {
			state.userInfo = val;
		},
	},
	actions: {
		setUserInfo({ dispatch, commit }, payload) {
			commit("SETUSERINFO", payload);
		},
	},
	modules: {},
});
